import { useQuery } from '@apollo/client';
import { dsGetProj } from 'datasources/dsProj/ds-proj';
import { IProj, TProjID } from 'types/datatypes/IProj';

export const useProj = () => {
  const { data } = useQuery(dsGetProj, { fetchPolicy: 'cache-first' });
  const projects = (data?.list ?? []) as IProj[];

  const isProjectEqualById = (id:TProjID, ref:string) => {
    return projects.find((r) => r.id === id)?.ref === ref;
  };

  const projById = (id:TProjID) => projects.find((r) => r.id === id);

  return {
    proj: projects,
    isProjectEqualById,
    projById,
  };
};
