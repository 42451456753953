/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query getAuth($user: String, $pass: String) {\n    auth(name: $user, pass: $pass)\n  }\n": types.GetAuthDocument,
    "\n  query logout {\n    logout\n  }\n": types.LogoutDocument,
    "\n  query getOptions($branch:String){ \n    getOptions (branch:$branch)\n  }\n": types.GetOptionsDocument,
    "\n  query loadInit($branch:String){ \n    getOptions (branch:$branch)\n    getLab (with_price:true)\n    getProj { id ref name predefined_name }\n  }\n": types.LoadInitDocument,
    "mutation sendNeedHelp($input: JSONObject) { sendNeedHelp(input: $input) }": types.SendNeedHelpDocument,
    "query getPartnerInfo($ref: String) {\n  infoapi(doc: \"contract\", ref: $ref)\n}": types.GetPartnerInfoDocument,
    "mutation setAppoint($input: JSONObject) {\n  setAppoint(input: $input)\n}": types.SetAppointDocument,
    "query re_auth{re_auth}": types.Re_AuthDocument,
    "query dsGetLabReports($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n  totalcount: lab_report(ref: $ref, jfilt: $jfilt, totalCount: 1 ) @include(if: $requireTotalCount) { totalcount }\n  list: lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n    ref amount status number_doc date has_error vehicle_map note gnumber warn_maybe_error\n    partner {id name}\n    service {id name}\n    vehicle_model {ref id name}\n    ext_json\n  }\n}": types.DsGetLabReportsDocument,
    "query dsGetLabReportsFull($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n    totalcount: lab_report(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) {totalcount}\n    list: lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n      ref  amount rp rv status number_doc date has_error vehicle_map note blank_series blank_number warn_maybe_error gnumber ext_json\n      partner { ref name individual_legal }\n      service { ref name }\n      invoice { ref caption number_doc doc_amount pay_kind date isSubContract ext_json badact_reason\n        services { nomRef gos_code quantity amount msto }\n      }  \n      vehicle_model {ref id name type {ref name} brand {ref name} }\n      source_report {ref}\n    }\n  }": types.DsGetLabReportsFullDocument,
    "query dsNews($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n  totalcount: getnews(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }\n  list: getnews(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) { \n    id date ref name images main_text need_approve news_priority\n  }\n}\n": types.DsNewsDocument,
    "query dsGetNoms($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {\n  totalcount: noms(ref: $ref, totalCount: 1) { totalcount }\n  list: noms(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt) {\n    ref \n    name \n    name_full \n    vat_rate\n    article\n    doc_type\n  }\n}": types.DsGetNomsDocument,
    "query dsGetServisesNoms($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {\n    totalcount: noms(ref: $ref, totalCount: 1, jfilt: $jfilt,options: {selectServices: true}) { totalcount }\n    list: noms(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt, options: {selectServices: true}) {\n      ref \n      name \n      name_full \n      vat_rate\n      article\n      doc_type\n    }\n  }": types.DsGetServisesNomsDocument,
    "query dsGetOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, \n  $sort: sort, $requireTotalCount: Boolean!,$userData:JSON\n) {\n  totalcount: buyers_orders(\n    ref: $ref, jfilt: $jfilt, totalCount: 1, userData: $userData\n  ) @include(if: $requireTotalCount) {\n    totalcount\n  }\n\n  list: buyers_orders(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, \n    sort: $sort, userData: $userData\n  ) {\n    ref \n    number_doc \n    date \n    doc_amount \n    caption \n    protected \n    shipped\n    paid\n    proj\n    pay_kind\n    note \n    partner { ref name }\n    services{ nomRef, quantity, amount, row, msto }\n    isSubContract\n    ext_json\n  }\n}": types.DsGetOrdersDocument,
    "query dsGetOrdersLookup($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n  totalcount: buyers_orders( ref: $ref, jfilt: $jfilt, totalCount: 1 ) @include(if: $requireTotalCount) {totalcount}\n  list: buyers_orders(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {ref number_doc date}\n}": types.DsGetOrdersLookupDocument,
    "query dsGetPartners($ref: String, $limit: Int, $jfilt: [JSON],$userData: JSON, $offset: Int) {\n  totalcount: partners(ref: $ref, jfilt: $jfilt, totalCount: 1, userData:$userData) {totalcount}\n  list: partners(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, userData:$userData) {\n    ref name edrpou id parent is_buyer is_supplier legal_address note name_full individual_legal inn isCorporate phones }\n}": types.DsGetPartnersDocument,
    "query dsGetProj {list: getProj { id ref name predefined_name}}": types.DsGetProjDocument,
    "subscription OnDocChanged($input: JSONObject, $className: String, $ref: String) {\n    docChange(input: $input, class_name: $className, ref: $ref)\n  }": types.OnDocChangedDocument,
    "query getLab($ref: String){getLab(ref: $ref)}": types.GetLabDocument,
    "query getOrgs($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {\n      # totalcount: getOrgs(ref: $ref, totalCount: 1) {totalcount}\n      getOrgs(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt) { ref name }\n  }": types.GetOrgsDocument,
    "query getPayKinds { getPayKindes { ref id pay_form name } }": types.GetPayKindsDocument,
    "query getPrices($date: String, $priceType: String) {\n  prices(date: $date, priceType: $priceType) { \n    nom \n    price \n    currency \n    vat_included \n  }\n}": types.GetPricesDocument,
    "mutation updateBuyersOrder($input: JSONObject) {\n  setBuyersOrder(input: $input) {\n    _id\n  }\n}": types.UpdateBuyersOrderDocument,
    "query getOrderCollections($ref: String) {\n    order: getOrderCollections(ref: $ref) {\n      _id\n      ref\n      doc_amount\n      number_doc\n      date\n      proj\n      note\n      ext_json\n      organization {ref name}\n      department { ref }\n      note\n      orders { row ref caption amount partnerName},\n    }\n    payment: checkEasyPayPaymentInfo(ref: $ref) { body }\n  }": types.GetOrderCollectionsDocument,
    "mutation setOrderCollections($input: JSONObject) {\n    setOrderCollections(input: $input) { _id }\n  }": types.SetOrderCollectionsDocument,
    "query dsActs($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean! ) {\n    totalcount: servise_selling(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }\n    list: servise_selling(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n      ref \n      date \n      number_doc  \n      doc_amount \n      note\n      partner { ref name } \n      trans { ref number_doc caption date }\n    }\n  }": types.DsActsDocument,
    "query senReport($start_date:String!, $end_date:String!){\n  createSendAssuranceReport(start_date:$start_date, end_date:$end_date)}": types.SenReportDocument,
    "query GetNomOptions{ getNomOptions { ref name period } }": types.GetNomOptionsDocument,
    "mutation UpdateNomOptions($input: JSONObject!){updateNomOptions(input: $input)}": types.UpdateNomOptionsDocument,
    "\n  mutation UpdateLabOptions($input: JSONObject, $ref: String) {\n    updateBranchOptions(input: $input, ref: $ref)\n  }\n": types.UpdateLabOptionsDocument,
    "\n  query getAdminAssuranceReport($start_date: String!, $end_date: String!, $labs: [String], $isPayment: Boolean) {\n    getAdminAssuranceReport(start_date: $start_date, end_date: $end_date, labs: $labs, isPayment: $isPayment)\n  }\n": types.GetAdminAssuranceReportDocument,
    "mutation sendMail($input: JSONObject) { sendMail(input: $input) }": types.SendMailDocument,
    "mutation AppendNewUser($input: JSONObject, $lab: JSONObject) {\n    appendUser(input: $input, lab: $lab)\n  }": types.AppendNewUserDocument,
    "query dsUsers($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!,$userData:JSON) {\n  totalcount: getUsers(ref: $ref, jfilt: $jfilt, totalCount: 1, userData: $userData) @include(if: $requireTotalCount) { totalcount }\n  list: getUsers(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort, userData: $userData) {\n      _deleted ref login name isBlocked isAdmin branch branches priceType priceTypeCash alterPriceType\n      history { created_at, modified_at}\n}}": types.DsUsersDocument,
    "query CRMGetPartners($ref: String!) {\n  partners(ref: $ref) { ref name edrpou id parent is_buyer is_supplier legal_address note name_full individual_legal inn isCorporate phones _data }\n  partner_ext(ref: $ref)\n}": types.CrmGetPartnersDocument,
    "mutation mutateNote($input: JSONObject!, $mode:String!) { updatePartnerExtensions(input: $input, mode:$mode) }": types.MutateNoteDocument,
    "query CRMPartners ($limit: Int, $offset: Int, $userData: JSON, $requireTotalCount: Boolean!) {\ntotalcount: crmPartners( userData:$userData, totalCount: 1 ) @include(if: $requireTotalCount) { totalcount }\nlist:  crmPartners(limit:$limit, offset:$offset, userData: $userData) {\n   ref name remind urgent_call lost\n  cars {\n    gnumber sname mvisit\n    reports {ref date number_doc amount } \n  }}\n}": types.CrmPartnersDocument,
    "query dsCRMLabReports($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort) {\n lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort, totalCount: 0) {\n    ref amount number_doc date has_error vehicle_map gnumber \n    # partner {id name}\n    service {id name}\n    # vehicle_model {ref id name}\n    # ext_json\n  }\n}": types.DsCrmLabReportsDocument,
    "query getDashOrders($limit: Int, $jfilt: [JSON]) {\n  list: buyers_orders(limit: $limit, jfilt: $jfilt) {\n    ref\n    date\n    services {\n      nom {\n        name\n      }\n    }\n  }\n}": types.GetDashOrdersDocument,
    "mutation DeleteDocument($fullId: String!) {\n    deleteDocument(fullId: $fullId)\n  }": types.DeleteDocumentDocument,
    "mutation updateEasyPayMessage($input: JSONObject) { setEasyPayMessage(input: $input) { _id }}": types.UpdateEasyPayMessageDocument,
    "query getEasyPayMessage($ref: String) {\n    getEasyPayMessage: getEasyPayMessage(ref: $ref) {\n    _id\n    ref\n    ext_json\n    number_doc\n    date\n    posted\n    totalsum\n    protected\n    caption\n    typeof_message\n    status\n    terminal_number\n    description\n    printscreen\n    moder_file\n    amount\n    proj\n    note\n    data_incident\n    department {ref name}\n    organization {ref name}\n    }\n  }": types.GetEasyPayMessageDocument,
    "query dsGetBlankOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n    totalcount: blanks(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }\n    list: blanks(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n      _id ref number_doc date doc_amount note trust_doc\n      partner { ref name }\n    }\n  }\n": types.DsGetBlankOrdersDocument,
    "mutation updateBlanksOrder($input: JSONObject) {\n  setBlanks(input: $input) {\n    _id\n  }\n}": types.UpdateBlanksOrderDocument,
    "query GetBlanksOrder($ref: String) {\n    blanks(ref: $ref) {\n      _id ref number_doc date doc_amount note trust_doc\n      partner { ref name }\n      pos_blank { nomType quantity amount price in_doc_number in_doc_date file_lab file_1c }\n    }\n  }": types.GetBlanksOrderDocument,
    "subscription OnEasyPayCallBack($orderId: String!){extCallBack(orderId: $orderId)}": types.OnEasyPayCallBackDocument,
    "query OrderServices($ref: String) {\n    buyers_orders(ref: $ref) {ref services{ nom { ref name name_full }, quantity, amount, row, msto }}}\n    ": types.OrderServicesDocument,
    "mutation updateLabReport($input: JSONObject) {\n    setLabReport(input: $input) {\n      _id\n    }\n  }": types.UpdateLabReportDocument,
    "query getLabReport($ref: String) {\n    lab_report(ref: $ref) {\n      _id ref caption\n      amount\n      posted\n      status\n      gnumber\n      vin\n      number_doc\n      date\n      dangerous\n      has_error\n      error\n      next_date\n      validated\n      vehicle_map\n      blank_number\n      blank_series\n      spot_cashless\n      warn_maybe_error\n      source_report {  ref   caption  }\n      rp\n      rv\n      note\n      totalcount\n      partner { ref name }\n      invoice { ref caption date pay_kind }\n      vehicle_model { name }\n      service { ref name }\n    }\n  }": types.GetLabReportDocument,
    "query dsOrdersForPay($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, \n    $sort: sort,\n    $requireTotalCount: Boolean!\n  ) {\n    totalcount: orderForPay(\n      ref: $ref, jfilt: $jfilt, totalCount: 1\n    ) @include(if: $requireTotalCount) {\n      totalcount\n    }\n  \n    list: orderForPay(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, \n      sort: $sort\n    ) {\n      ref \n      number_doc \n      date \n      partner {\n        ref \n        name\n      } \n      note \n      doc_amount \n      trust_doc\n      account_kind\n    }\n  }": types.DsOrdersForPayDocument,
    "\nquery orderForPay($ref: String) {\n  orderForPay(ref: $ref) {\n    _id _rev\n   ref number_doc input_doc_number date \n   partner {ref name} \n   note doc_amount trust_doc account_kind\n   pos_blank {\n    row \n    nom {ref name}\n    nomType price quantity amount vat_rate vat_amount coefficient in_doc_number in_doc_date file_lab file_1c }\n  }\n}": types.OrderForPayDocument,
    "mutation setOrderForPay($input: JSONObject){setOrderForPay(input: $input)}": types.SetOrderForPayDocument,
    "query contractInfo($ref: String!, $date: String!) {\n  contractInfo(ref: $ref, date: $date) {\n    id\n    name\n    nameForPrint\n    dogNumber\n    dogDate\n    validDate\n    isSubContract\n    options {\n      prices {\n        nom\n        nomname\n        value\n        validTo\n      }\n      disconts {\n        nom\n        nomname\n        value\n        validTo\n      }\n    }\n  }\n}": types.ContractInfoDocument,
    "mutation createActfromOrder($buyers_order: String) {\n  createActfromOrder(buyers_order: $buyers_order)\n}": types.CreateActfromOrderDocument,
    "query getPriceOrder($ref: String) {\n  priceorder(ref: $ref) {\n    ref number_doc date\n    start_date expiration_date\n    transactions_kind\n    status proj \n    note\n    partner { ref name }\n    goods {\n      row\n      newprice\n      discount_percent\n      quantity\n      nom { ref name }\n    }\n  }\n}": types.GetPriceOrderDocument,
    "query dsGetPriceOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, \n  $sort: sort,\n  $requireTotalCount: Boolean!\n) {\n  totalcount: priceorder(\n    ref: $ref, jfilt: $jfilt, totalCount: 1\n  ) @include(if: $requireTotalCount) {\n    totalcount\n  }\n\n  list: priceorder(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, \n    sort: $sort\n  ) {\n    ref \n    number_doc \n    date \n    partner {\n      ref \n      name\n    } \n    note \n    quantity \n    status \n    transactions_kind\n  }\n}": types.DsGetPriceOrdersDocument,
    "mutation updatePriceOrder($input: JSONObject) {\n  setPriceOrder(input: $input) {\n    _id\n  }\n}": types.UpdatePriceOrderDocument,
    "mutation sendLabDayReport($input: JSONObject) {sendLabDayReport(input: $input)}": types.SendLabDayReportDocument,
    "\nquery dsPays($start_date: String!, $end_date: String!, $payKind: String!, $status: Int!, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\nlist: getPayReport(start_date: $start_date, end_date: $end_date, payKind:$payKind, status: $status,  requireTotalCount: false, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort)\ntotalcount: getPayReport(start_date: $start_date, end_date: $end_date, payKind:$payKind, status: $status,  requireTotalCount: $requireTotalCount, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort)\n    @include(if: $requireTotalCount) \n}": types.DsPaysDocument,
    "query terminalDocs($limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!, $userData: userData)\t{\n  totalcount: terminalDocs(jfilt: $jfilt, totalCount: 1, userData: $userData) @include(if: $requireTotalCount) { totalcount }\n  list: terminalDocs(limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort, userData: $userData) { ref date number_doc caption doc_amount payer doctype proj partner {name} }\n}\n": types.TerminalDocsDocument,
    "mutation updateWorkTime($input: JSONObject) {\n  setWorkTime(input: $input) {\n    _id\n  }\n}": types.UpdateWorkTimeDocument,
    "mutation updateBranchOptions($input: JSONObject) { updateBranchOptions(input: $input)}": types.UpdateBranchOptionsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAuth($user: String, $pass: String) {\n    auth(name: $user, pass: $pass)\n  }\n"): (typeof documents)["\n  query getAuth($user: String, $pass: String) {\n    auth(name: $user, pass: $pass)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query logout {\n    logout\n  }\n"): (typeof documents)["\n  query logout {\n    logout\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getOptions($branch:String){ \n    getOptions (branch:$branch)\n  }\n"): (typeof documents)["\n  query getOptions($branch:String){ \n    getOptions (branch:$branch)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query loadInit($branch:String){ \n    getOptions (branch:$branch)\n    getLab (with_price:true)\n    getProj { id ref name predefined_name }\n  }\n"): (typeof documents)["\n  query loadInit($branch:String){ \n    getOptions (branch:$branch)\n    getLab (with_price:true)\n    getProj { id ref name predefined_name }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation sendNeedHelp($input: JSONObject) { sendNeedHelp(input: $input) }"): (typeof documents)["mutation sendNeedHelp($input: JSONObject) { sendNeedHelp(input: $input) }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getPartnerInfo($ref: String) {\n  infoapi(doc: \"contract\", ref: $ref)\n}"): (typeof documents)["query getPartnerInfo($ref: String) {\n  infoapi(doc: \"contract\", ref: $ref)\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation setAppoint($input: JSONObject) {\n  setAppoint(input: $input)\n}"): (typeof documents)["mutation setAppoint($input: JSONObject) {\n  setAppoint(input: $input)\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query re_auth{re_auth}"): (typeof documents)["query re_auth{re_auth}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsGetLabReports($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n  totalcount: lab_report(ref: $ref, jfilt: $jfilt, totalCount: 1 ) @include(if: $requireTotalCount) { totalcount }\n  list: lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n    ref amount status number_doc date has_error vehicle_map note gnumber warn_maybe_error\n    partner {id name}\n    service {id name}\n    vehicle_model {ref id name}\n    ext_json\n  }\n}"): (typeof documents)["query dsGetLabReports($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n  totalcount: lab_report(ref: $ref, jfilt: $jfilt, totalCount: 1 ) @include(if: $requireTotalCount) { totalcount }\n  list: lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n    ref amount status number_doc date has_error vehicle_map note gnumber warn_maybe_error\n    partner {id name}\n    service {id name}\n    vehicle_model {ref id name}\n    ext_json\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsGetLabReportsFull($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n    totalcount: lab_report(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) {totalcount}\n    list: lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n      ref  amount rp rv status number_doc date has_error vehicle_map note blank_series blank_number warn_maybe_error gnumber ext_json\n      partner { ref name individual_legal }\n      service { ref name }\n      invoice { ref caption number_doc doc_amount pay_kind date isSubContract ext_json badact_reason\n        services { nomRef gos_code quantity amount msto }\n      }  \n      vehicle_model {ref id name type {ref name} brand {ref name} }\n      source_report {ref}\n    }\n  }"): (typeof documents)["query dsGetLabReportsFull($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n    totalcount: lab_report(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) {totalcount}\n    list: lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n      ref  amount rp rv status number_doc date has_error vehicle_map note blank_series blank_number warn_maybe_error gnumber ext_json\n      partner { ref name individual_legal }\n      service { ref name }\n      invoice { ref caption number_doc doc_amount pay_kind date isSubContract ext_json badact_reason\n        services { nomRef gos_code quantity amount msto }\n      }  \n      vehicle_model {ref id name type {ref name} brand {ref name} }\n      source_report {ref}\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsNews($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n  totalcount: getnews(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }\n  list: getnews(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) { \n    id date ref name images main_text need_approve news_priority\n  }\n}\n"): (typeof documents)["query dsNews($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n  totalcount: getnews(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }\n  list: getnews(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) { \n    id date ref name images main_text need_approve news_priority\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsGetNoms($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {\n  totalcount: noms(ref: $ref, totalCount: 1) { totalcount }\n  list: noms(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt) {\n    ref \n    name \n    name_full \n    vat_rate\n    article\n    doc_type\n  }\n}"): (typeof documents)["query dsGetNoms($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {\n  totalcount: noms(ref: $ref, totalCount: 1) { totalcount }\n  list: noms(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt) {\n    ref \n    name \n    name_full \n    vat_rate\n    article\n    doc_type\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsGetServisesNoms($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {\n    totalcount: noms(ref: $ref, totalCount: 1, jfilt: $jfilt,options: {selectServices: true}) { totalcount }\n    list: noms(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt, options: {selectServices: true}) {\n      ref \n      name \n      name_full \n      vat_rate\n      article\n      doc_type\n    }\n  }"): (typeof documents)["query dsGetServisesNoms($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {\n    totalcount: noms(ref: $ref, totalCount: 1, jfilt: $jfilt,options: {selectServices: true}) { totalcount }\n    list: noms(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt, options: {selectServices: true}) {\n      ref \n      name \n      name_full \n      vat_rate\n      article\n      doc_type\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsGetOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, \n  $sort: sort, $requireTotalCount: Boolean!,$userData:JSON\n) {\n  totalcount: buyers_orders(\n    ref: $ref, jfilt: $jfilt, totalCount: 1, userData: $userData\n  ) @include(if: $requireTotalCount) {\n    totalcount\n  }\n\n  list: buyers_orders(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, \n    sort: $sort, userData: $userData\n  ) {\n    ref \n    number_doc \n    date \n    doc_amount \n    caption \n    protected \n    shipped\n    paid\n    proj\n    pay_kind\n    note \n    partner { ref name }\n    services{ nomRef, quantity, amount, row, msto }\n    isSubContract\n    ext_json\n  }\n}"): (typeof documents)["query dsGetOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, \n  $sort: sort, $requireTotalCount: Boolean!,$userData:JSON\n) {\n  totalcount: buyers_orders(\n    ref: $ref, jfilt: $jfilt, totalCount: 1, userData: $userData\n  ) @include(if: $requireTotalCount) {\n    totalcount\n  }\n\n  list: buyers_orders(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, \n    sort: $sort, userData: $userData\n  ) {\n    ref \n    number_doc \n    date \n    doc_amount \n    caption \n    protected \n    shipped\n    paid\n    proj\n    pay_kind\n    note \n    partner { ref name }\n    services{ nomRef, quantity, amount, row, msto }\n    isSubContract\n    ext_json\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsGetOrdersLookup($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n  totalcount: buyers_orders( ref: $ref, jfilt: $jfilt, totalCount: 1 ) @include(if: $requireTotalCount) {totalcount}\n  list: buyers_orders(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {ref number_doc date}\n}"): (typeof documents)["query dsGetOrdersLookup($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n  totalcount: buyers_orders( ref: $ref, jfilt: $jfilt, totalCount: 1 ) @include(if: $requireTotalCount) {totalcount}\n  list: buyers_orders(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {ref number_doc date}\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsGetPartners($ref: String, $limit: Int, $jfilt: [JSON],$userData: JSON, $offset: Int) {\n  totalcount: partners(ref: $ref, jfilt: $jfilt, totalCount: 1, userData:$userData) {totalcount}\n  list: partners(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, userData:$userData) {\n    ref name edrpou id parent is_buyer is_supplier legal_address note name_full individual_legal inn isCorporate phones }\n}"): (typeof documents)["query dsGetPartners($ref: String, $limit: Int, $jfilt: [JSON],$userData: JSON, $offset: Int) {\n  totalcount: partners(ref: $ref, jfilt: $jfilt, totalCount: 1, userData:$userData) {totalcount}\n  list: partners(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, userData:$userData) {\n    ref name edrpou id parent is_buyer is_supplier legal_address note name_full individual_legal inn isCorporate phones }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsGetProj {list: getProj { id ref name predefined_name}}"): (typeof documents)["query dsGetProj {list: getProj { id ref name predefined_name}}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "subscription OnDocChanged($input: JSONObject, $className: String, $ref: String) {\n    docChange(input: $input, class_name: $className, ref: $ref)\n  }"): (typeof documents)["subscription OnDocChanged($input: JSONObject, $className: String, $ref: String) {\n    docChange(input: $input, class_name: $className, ref: $ref)\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getLab($ref: String){getLab(ref: $ref)}"): (typeof documents)["query getLab($ref: String){getLab(ref: $ref)}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getOrgs($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {\n      # totalcount: getOrgs(ref: $ref, totalCount: 1) {totalcount}\n      getOrgs(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt) { ref name }\n  }"): (typeof documents)["query getOrgs($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {\n      # totalcount: getOrgs(ref: $ref, totalCount: 1) {totalcount}\n      getOrgs(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt) { ref name }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getPayKinds { getPayKindes { ref id pay_form name } }"): (typeof documents)["query getPayKinds { getPayKindes { ref id pay_form name } }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getPrices($date: String, $priceType: String) {\n  prices(date: $date, priceType: $priceType) { \n    nom \n    price \n    currency \n    vat_included \n  }\n}"): (typeof documents)["query getPrices($date: String, $priceType: String) {\n  prices(date: $date, priceType: $priceType) { \n    nom \n    price \n    currency \n    vat_included \n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updateBuyersOrder($input: JSONObject) {\n  setBuyersOrder(input: $input) {\n    _id\n  }\n}"): (typeof documents)["mutation updateBuyersOrder($input: JSONObject) {\n  setBuyersOrder(input: $input) {\n    _id\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getOrderCollections($ref: String) {\n    order: getOrderCollections(ref: $ref) {\n      _id\n      ref\n      doc_amount\n      number_doc\n      date\n      proj\n      note\n      ext_json\n      organization {ref name}\n      department { ref }\n      note\n      orders { row ref caption amount partnerName},\n    }\n    payment: checkEasyPayPaymentInfo(ref: $ref) { body }\n  }"): (typeof documents)["query getOrderCollections($ref: String) {\n    order: getOrderCollections(ref: $ref) {\n      _id\n      ref\n      doc_amount\n      number_doc\n      date\n      proj\n      note\n      ext_json\n      organization {ref name}\n      department { ref }\n      note\n      orders { row ref caption amount partnerName},\n    }\n    payment: checkEasyPayPaymentInfo(ref: $ref) { body }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation setOrderCollections($input: JSONObject) {\n    setOrderCollections(input: $input) { _id }\n  }"): (typeof documents)["mutation setOrderCollections($input: JSONObject) {\n    setOrderCollections(input: $input) { _id }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsActs($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean! ) {\n    totalcount: servise_selling(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }\n    list: servise_selling(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n      ref \n      date \n      number_doc  \n      doc_amount \n      note\n      partner { ref name } \n      trans { ref number_doc caption date }\n    }\n  }"): (typeof documents)["query dsActs($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean! ) {\n    totalcount: servise_selling(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }\n    list: servise_selling(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n      ref \n      date \n      number_doc  \n      doc_amount \n      note\n      partner { ref name } \n      trans { ref number_doc caption date }\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query senReport($start_date:String!, $end_date:String!){\n  createSendAssuranceReport(start_date:$start_date, end_date:$end_date)}"): (typeof documents)["query senReport($start_date:String!, $end_date:String!){\n  createSendAssuranceReport(start_date:$start_date, end_date:$end_date)}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetNomOptions{ getNomOptions { ref name period } }"): (typeof documents)["query GetNomOptions{ getNomOptions { ref name period } }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation UpdateNomOptions($input: JSONObject!){updateNomOptions(input: $input)}"): (typeof documents)["mutation UpdateNomOptions($input: JSONObject!){updateNomOptions(input: $input)}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateLabOptions($input: JSONObject, $ref: String) {\n    updateBranchOptions(input: $input, ref: $ref)\n  }\n"): (typeof documents)["\n  mutation UpdateLabOptions($input: JSONObject, $ref: String) {\n    updateBranchOptions(input: $input, ref: $ref)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAdminAssuranceReport($start_date: String!, $end_date: String!, $labs: [String], $isPayment: Boolean) {\n    getAdminAssuranceReport(start_date: $start_date, end_date: $end_date, labs: $labs, isPayment: $isPayment)\n  }\n"): (typeof documents)["\n  query getAdminAssuranceReport($start_date: String!, $end_date: String!, $labs: [String], $isPayment: Boolean) {\n    getAdminAssuranceReport(start_date: $start_date, end_date: $end_date, labs: $labs, isPayment: $isPayment)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation sendMail($input: JSONObject) { sendMail(input: $input) }"): (typeof documents)["mutation sendMail($input: JSONObject) { sendMail(input: $input) }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation AppendNewUser($input: JSONObject, $lab: JSONObject) {\n    appendUser(input: $input, lab: $lab)\n  }"): (typeof documents)["mutation AppendNewUser($input: JSONObject, $lab: JSONObject) {\n    appendUser(input: $input, lab: $lab)\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsUsers($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!,$userData:JSON) {\n  totalcount: getUsers(ref: $ref, jfilt: $jfilt, totalCount: 1, userData: $userData) @include(if: $requireTotalCount) { totalcount }\n  list: getUsers(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort, userData: $userData) {\n      _deleted ref login name isBlocked isAdmin branch branches priceType priceTypeCash alterPriceType\n      history { created_at, modified_at}\n}}"): (typeof documents)["query dsUsers($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!,$userData:JSON) {\n  totalcount: getUsers(ref: $ref, jfilt: $jfilt, totalCount: 1, userData: $userData) @include(if: $requireTotalCount) { totalcount }\n  list: getUsers(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort, userData: $userData) {\n      _deleted ref login name isBlocked isAdmin branch branches priceType priceTypeCash alterPriceType\n      history { created_at, modified_at}\n}}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query CRMGetPartners($ref: String!) {\n  partners(ref: $ref) { ref name edrpou id parent is_buyer is_supplier legal_address note name_full individual_legal inn isCorporate phones _data }\n  partner_ext(ref: $ref)\n}"): (typeof documents)["query CRMGetPartners($ref: String!) {\n  partners(ref: $ref) { ref name edrpou id parent is_buyer is_supplier legal_address note name_full individual_legal inn isCorporate phones _data }\n  partner_ext(ref: $ref)\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation mutateNote($input: JSONObject!, $mode:String!) { updatePartnerExtensions(input: $input, mode:$mode) }"): (typeof documents)["mutation mutateNote($input: JSONObject!, $mode:String!) { updatePartnerExtensions(input: $input, mode:$mode) }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query CRMPartners ($limit: Int, $offset: Int, $userData: JSON, $requireTotalCount: Boolean!) {\ntotalcount: crmPartners( userData:$userData, totalCount: 1 ) @include(if: $requireTotalCount) { totalcount }\nlist:  crmPartners(limit:$limit, offset:$offset, userData: $userData) {\n   ref name remind urgent_call lost\n  cars {\n    gnumber sname mvisit\n    reports {ref date number_doc amount } \n  }}\n}"): (typeof documents)["query CRMPartners ($limit: Int, $offset: Int, $userData: JSON, $requireTotalCount: Boolean!) {\ntotalcount: crmPartners( userData:$userData, totalCount: 1 ) @include(if: $requireTotalCount) { totalcount }\nlist:  crmPartners(limit:$limit, offset:$offset, userData: $userData) {\n   ref name remind urgent_call lost\n  cars {\n    gnumber sname mvisit\n    reports {ref date number_doc amount } \n  }}\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsCRMLabReports($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort) {\n lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort, totalCount: 0) {\n    ref amount number_doc date has_error vehicle_map gnumber \n    # partner {id name}\n    service {id name}\n    # vehicle_model {ref id name}\n    # ext_json\n  }\n}"): (typeof documents)["query dsCRMLabReports($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort) {\n lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort, totalCount: 0) {\n    ref amount number_doc date has_error vehicle_map gnumber \n    # partner {id name}\n    service {id name}\n    # vehicle_model {ref id name}\n    # ext_json\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getDashOrders($limit: Int, $jfilt: [JSON]) {\n  list: buyers_orders(limit: $limit, jfilt: $jfilt) {\n    ref\n    date\n    services {\n      nom {\n        name\n      }\n    }\n  }\n}"): (typeof documents)["query getDashOrders($limit: Int, $jfilt: [JSON]) {\n  list: buyers_orders(limit: $limit, jfilt: $jfilt) {\n    ref\n    date\n    services {\n      nom {\n        name\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation DeleteDocument($fullId: String!) {\n    deleteDocument(fullId: $fullId)\n  }"): (typeof documents)["mutation DeleteDocument($fullId: String!) {\n    deleteDocument(fullId: $fullId)\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updateEasyPayMessage($input: JSONObject) { setEasyPayMessage(input: $input) { _id }}"): (typeof documents)["mutation updateEasyPayMessage($input: JSONObject) { setEasyPayMessage(input: $input) { _id }}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getEasyPayMessage($ref: String) {\n    getEasyPayMessage: getEasyPayMessage(ref: $ref) {\n    _id\n    ref\n    ext_json\n    number_doc\n    date\n    posted\n    totalsum\n    protected\n    caption\n    typeof_message\n    status\n    terminal_number\n    description\n    printscreen\n    moder_file\n    amount\n    proj\n    note\n    data_incident\n    department {ref name}\n    organization {ref name}\n    }\n  }"): (typeof documents)["query getEasyPayMessage($ref: String) {\n    getEasyPayMessage: getEasyPayMessage(ref: $ref) {\n    _id\n    ref\n    ext_json\n    number_doc\n    date\n    posted\n    totalsum\n    protected\n    caption\n    typeof_message\n    status\n    terminal_number\n    description\n    printscreen\n    moder_file\n    amount\n    proj\n    note\n    data_incident\n    department {ref name}\n    organization {ref name}\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsGetBlankOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n    totalcount: blanks(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }\n    list: blanks(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n      _id ref number_doc date doc_amount note trust_doc\n      partner { ref name }\n    }\n  }\n"): (typeof documents)["query dsGetBlankOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\n    totalcount: blanks(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }\n    list: blanks(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {\n      _id ref number_doc date doc_amount note trust_doc\n      partner { ref name }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updateBlanksOrder($input: JSONObject) {\n  setBlanks(input: $input) {\n    _id\n  }\n}"): (typeof documents)["mutation updateBlanksOrder($input: JSONObject) {\n  setBlanks(input: $input) {\n    _id\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetBlanksOrder($ref: String) {\n    blanks(ref: $ref) {\n      _id ref number_doc date doc_amount note trust_doc\n      partner { ref name }\n      pos_blank { nomType quantity amount price in_doc_number in_doc_date file_lab file_1c }\n    }\n  }"): (typeof documents)["query GetBlanksOrder($ref: String) {\n    blanks(ref: $ref) {\n      _id ref number_doc date doc_amount note trust_doc\n      partner { ref name }\n      pos_blank { nomType quantity amount price in_doc_number in_doc_date file_lab file_1c }\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "subscription OnEasyPayCallBack($orderId: String!){extCallBack(orderId: $orderId)}"): (typeof documents)["subscription OnEasyPayCallBack($orderId: String!){extCallBack(orderId: $orderId)}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query OrderServices($ref: String) {\n    buyers_orders(ref: $ref) {ref services{ nom { ref name name_full }, quantity, amount, row, msto }}}\n    "): (typeof documents)["query OrderServices($ref: String) {\n    buyers_orders(ref: $ref) {ref services{ nom { ref name name_full }, quantity, amount, row, msto }}}\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updateLabReport($input: JSONObject) {\n    setLabReport(input: $input) {\n      _id\n    }\n  }"): (typeof documents)["mutation updateLabReport($input: JSONObject) {\n    setLabReport(input: $input) {\n      _id\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getLabReport($ref: String) {\n    lab_report(ref: $ref) {\n      _id ref caption\n      amount\n      posted\n      status\n      gnumber\n      vin\n      number_doc\n      date\n      dangerous\n      has_error\n      error\n      next_date\n      validated\n      vehicle_map\n      blank_number\n      blank_series\n      spot_cashless\n      warn_maybe_error\n      source_report {  ref   caption  }\n      rp\n      rv\n      note\n      totalcount\n      partner { ref name }\n      invoice { ref caption date pay_kind }\n      vehicle_model { name }\n      service { ref name }\n    }\n  }"): (typeof documents)["query getLabReport($ref: String) {\n    lab_report(ref: $ref) {\n      _id ref caption\n      amount\n      posted\n      status\n      gnumber\n      vin\n      number_doc\n      date\n      dangerous\n      has_error\n      error\n      next_date\n      validated\n      vehicle_map\n      blank_number\n      blank_series\n      spot_cashless\n      warn_maybe_error\n      source_report {  ref   caption  }\n      rp\n      rv\n      note\n      totalcount\n      partner { ref name }\n      invoice { ref caption date pay_kind }\n      vehicle_model { name }\n      service { ref name }\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsOrdersForPay($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, \n    $sort: sort,\n    $requireTotalCount: Boolean!\n  ) {\n    totalcount: orderForPay(\n      ref: $ref, jfilt: $jfilt, totalCount: 1\n    ) @include(if: $requireTotalCount) {\n      totalcount\n    }\n  \n    list: orderForPay(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, \n      sort: $sort\n    ) {\n      ref \n      number_doc \n      date \n      partner {\n        ref \n        name\n      } \n      note \n      doc_amount \n      trust_doc\n      account_kind\n    }\n  }"): (typeof documents)["query dsOrdersForPay($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, \n    $sort: sort,\n    $requireTotalCount: Boolean!\n  ) {\n    totalcount: orderForPay(\n      ref: $ref, jfilt: $jfilt, totalCount: 1\n    ) @include(if: $requireTotalCount) {\n      totalcount\n    }\n  \n    list: orderForPay(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, \n      sort: $sort\n    ) {\n      ref \n      number_doc \n      date \n      partner {\n        ref \n        name\n      } \n      note \n      doc_amount \n      trust_doc\n      account_kind\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery orderForPay($ref: String) {\n  orderForPay(ref: $ref) {\n    _id _rev\n   ref number_doc input_doc_number date \n   partner {ref name} \n   note doc_amount trust_doc account_kind\n   pos_blank {\n    row \n    nom {ref name}\n    nomType price quantity amount vat_rate vat_amount coefficient in_doc_number in_doc_date file_lab file_1c }\n  }\n}"): (typeof documents)["\nquery orderForPay($ref: String) {\n  orderForPay(ref: $ref) {\n    _id _rev\n   ref number_doc input_doc_number date \n   partner {ref name} \n   note doc_amount trust_doc account_kind\n   pos_blank {\n    row \n    nom {ref name}\n    nomType price quantity amount vat_rate vat_amount coefficient in_doc_number in_doc_date file_lab file_1c }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation setOrderForPay($input: JSONObject){setOrderForPay(input: $input)}"): (typeof documents)["mutation setOrderForPay($input: JSONObject){setOrderForPay(input: $input)}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query contractInfo($ref: String!, $date: String!) {\n  contractInfo(ref: $ref, date: $date) {\n    id\n    name\n    nameForPrint\n    dogNumber\n    dogDate\n    validDate\n    isSubContract\n    options {\n      prices {\n        nom\n        nomname\n        value\n        validTo\n      }\n      disconts {\n        nom\n        nomname\n        value\n        validTo\n      }\n    }\n  }\n}"): (typeof documents)["query contractInfo($ref: String!, $date: String!) {\n  contractInfo(ref: $ref, date: $date) {\n    id\n    name\n    nameForPrint\n    dogNumber\n    dogDate\n    validDate\n    isSubContract\n    options {\n      prices {\n        nom\n        nomname\n        value\n        validTo\n      }\n      disconts {\n        nom\n        nomname\n        value\n        validTo\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation createActfromOrder($buyers_order: String) {\n  createActfromOrder(buyers_order: $buyers_order)\n}"): (typeof documents)["mutation createActfromOrder($buyers_order: String) {\n  createActfromOrder(buyers_order: $buyers_order)\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getPriceOrder($ref: String) {\n  priceorder(ref: $ref) {\n    ref number_doc date\n    start_date expiration_date\n    transactions_kind\n    status proj \n    note\n    partner { ref name }\n    goods {\n      row\n      newprice\n      discount_percent\n      quantity\n      nom { ref name }\n    }\n  }\n}"): (typeof documents)["query getPriceOrder($ref: String) {\n  priceorder(ref: $ref) {\n    ref number_doc date\n    start_date expiration_date\n    transactions_kind\n    status proj \n    note\n    partner { ref name }\n    goods {\n      row\n      newprice\n      discount_percent\n      quantity\n      nom { ref name }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query dsGetPriceOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, \n  $sort: sort,\n  $requireTotalCount: Boolean!\n) {\n  totalcount: priceorder(\n    ref: $ref, jfilt: $jfilt, totalCount: 1\n  ) @include(if: $requireTotalCount) {\n    totalcount\n  }\n\n  list: priceorder(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, \n    sort: $sort\n  ) {\n    ref \n    number_doc \n    date \n    partner {\n      ref \n      name\n    } \n    note \n    quantity \n    status \n    transactions_kind\n  }\n}"): (typeof documents)["query dsGetPriceOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, \n  $sort: sort,\n  $requireTotalCount: Boolean!\n) {\n  totalcount: priceorder(\n    ref: $ref, jfilt: $jfilt, totalCount: 1\n  ) @include(if: $requireTotalCount) {\n    totalcount\n  }\n\n  list: priceorder(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, \n    sort: $sort\n  ) {\n    ref \n    number_doc \n    date \n    partner {\n      ref \n      name\n    } \n    note \n    quantity \n    status \n    transactions_kind\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updatePriceOrder($input: JSONObject) {\n  setPriceOrder(input: $input) {\n    _id\n  }\n}"): (typeof documents)["mutation updatePriceOrder($input: JSONObject) {\n  setPriceOrder(input: $input) {\n    _id\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation sendLabDayReport($input: JSONObject) {sendLabDayReport(input: $input)}"): (typeof documents)["mutation sendLabDayReport($input: JSONObject) {sendLabDayReport(input: $input)}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery dsPays($start_date: String!, $end_date: String!, $payKind: String!, $status: Int!, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\nlist: getPayReport(start_date: $start_date, end_date: $end_date, payKind:$payKind, status: $status,  requireTotalCount: false, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort)\ntotalcount: getPayReport(start_date: $start_date, end_date: $end_date, payKind:$payKind, status: $status,  requireTotalCount: $requireTotalCount, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort)\n    @include(if: $requireTotalCount) \n}"): (typeof documents)["\nquery dsPays($start_date: String!, $end_date: String!, $payKind: String!, $status: Int!, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {\nlist: getPayReport(start_date: $start_date, end_date: $end_date, payKind:$payKind, status: $status,  requireTotalCount: false, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort)\ntotalcount: getPayReport(start_date: $start_date, end_date: $end_date, payKind:$payKind, status: $status,  requireTotalCount: $requireTotalCount, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort)\n    @include(if: $requireTotalCount) \n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query terminalDocs($limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!, $userData: userData)\t{\n  totalcount: terminalDocs(jfilt: $jfilt, totalCount: 1, userData: $userData) @include(if: $requireTotalCount) { totalcount }\n  list: terminalDocs(limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort, userData: $userData) { ref date number_doc caption doc_amount payer doctype proj partner {name} }\n}\n"): (typeof documents)["query terminalDocs($limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!, $userData: userData)\t{\n  totalcount: terminalDocs(jfilt: $jfilt, totalCount: 1, userData: $userData) @include(if: $requireTotalCount) { totalcount }\n  list: terminalDocs(limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort, userData: $userData) { ref date number_doc caption doc_amount payer doctype proj partner {name} }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updateWorkTime($input: JSONObject) {\n  setWorkTime(input: $input) {\n    _id\n  }\n}"): (typeof documents)["mutation updateWorkTime($input: JSONObject) {\n  setWorkTime(input: $input) {\n    _id\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updateBranchOptions($input: JSONObject) { updateBranchOptions(input: $input)}"): (typeof documents)["mutation updateBranchOptions($input: JSONObject) { updateBranchOptions(input: $input)}"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;