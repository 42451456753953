import { useEffect, useState } from 'react';
import { ISelectBoxOptions, SelectBox } from 'devextreme-react/ui/select-box';
import { useAuth } from 'contexts';


interface SelectProjectProps extends ISelectBoxOptions {
  onValueChange: (value: any) => void;
  availableOnly?:boolean;
  items?: any[];//={projects.available}
  value:string|undefined;//={doc?.proj ?? projects.default.ref}
  label?:string;
}

export const SelectProject = ({ value, items, onValueChange, availableOnly=false, label, ...rest }:SelectProjectProps) => {
  const { projects } = useAuth();
  const [ _proj, setProj ] = useState(value ?? projects.default);
  
  useEffect(() => setProj(value  ?? projects.default)
  , [projects.default, value]);

  const handleValueChange = (e:any) => {
    onValueChange(e);
    setProj(e);
  };

  return (
    <SelectBox
      {...rest}
      items={ availableOnly ? projects.available:projects.items}
      displayExpr='name'
      valueExpr='ref'
      onValueChange={handleValueChange}
      value={_proj}
      placeholder=''
      hint='тип замовлення'
      label={label ?? 'Тип замовлення'}
    />
  );
};
